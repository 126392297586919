<template>
  <v-container fluid text-xs-center>
    <v-card v-if="companyDetails.enableWhatsapp && !whatsappRegistered" class="secondarygrad pa-2 fill-height rounded">
      <div class="d-flex justify-center">
        <v-avatar size="62" class="elevation-2">
          <v-img src="../../assets/integrations/fb.png" alt="facebook" style="padding: 30%" />
          <!-- <img :src="integration.image" :alt="integration.name" style="" /> -->
        </v-avatar>
        <span style="padding-top: 20px;padding-left: 10px;font-weight: 700;">Facebook Business</span>
      </div>

      <v-layout row wrap style="margin: 0px">
        <v-flex class="align" xs12 style="padding: 20px">
          <div>
            <!-- <v-card class="d-flex align-center justify-start px-4 mb-2" color="#F57F17">
              <v-icon style="color: white;"></v-icon>
              <v-card-subtitle class="mx-0 px-1" style="color: white;">
                This version is a prototype. Currently, you can send test
                messages. Keep an eye out for upcoming advanced features.
              </v-card-subtitle>
            </v-card> -->
            <v-flex v-if="!isConnected && !whatsappRegistered"
              class="d-flex flex-wrap justify-space-between align-center pa-2">
              <v-card-subtitle>You need to connect your facebook business account in order to
                use Whatssapp business</v-card-subtitle>
              <v-btn class="fb-button mx-sm-4" color="primarygrad" @click="$router.push('/inbox')">
                Connect Facebook Business
              </v-btn>
            </v-flex>
          </div>
        </v-flex>
      </v-layout>
    </v-card>

    <!-- <v-card v-else class="my-2 px-5 py-3 d-block d-sm-flex justify-space-between elevation-2" two-line>
      <div class="d-flex align-center">
        <v-btn color="primarygrad" fab small>
          <v-icon color="white">mdi-currency-inr</v-icon>
        </v-btn>
        <p class="mb-0 mx-3">
          Remove Whatsapp Connection
        </p>
      </div>
    </v-card> -->
    <v-layout row wrap class="my-3">
      <v-flex xs12 sm12 md12 style="padding: 1%;">
        <v-card>
          <v-card-title>
            <v-icon large>mdi-whatsapp</v-icon>
            <span class="title" style="padding-left: 5px">Whatsapp</span>
          </v-card-title>
          <v-card-text>
            <div align="center">
              <h2 style="padding-bottom: 1.5%;"></h2>
            </div>
            <div align="center" style="padding: 10px">
              You need to enable whatsapp in order to use Facebook
              business.Please enable whatsapp here and also at agent's setting
              page to ensure whatsapp are enabled.
            </div>

            <v-list-item class="enableFacebookPadding">
              <v-list-item-icon> </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>
                  <v-icon style="padding:2%">mdi-whatsapp</v-icon>Enable
                  Whatsapp
                </v-list-item-title>
              </v-list-item-content>

              <v-list-item-action>
                <v-switch v-model="enableWhatsapp" class="mt-5" inset @change="updateEnableWhatsapp"></v-switch>
              </v-list-item-action>
            </v-list-item>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>

    <v-card v-if="companyDetails.enableWhatsapp &&
      whatsappRegistered &&
      wappIntegrationDetails
      " class="secondarygrad pa-2 fill-height rounded">
      <v-card-title class="d-flex flex-wrap align-center justify-space-between">
        <div>
          <v-icon large>mdi-whatsapp</v-icon>
          <span class="title" style="padding-left: 5px">Whatsapp Details</span>
        </div>

        <div class="d-flex flex-row-reverse">
          <v-btn color="primarygrad" @click="handleDeletePopup">
            Remove
          </v-btn>
        </div>
      </v-card-title>
      <v-card-text>
        <v-simple-table dense>
          <template v-slot:default>
            <tbody>
              <tr>
                <td>Whatsapp Business Id</td>
                <td>{{ wappIntegrationDetails.config.whatsappBusinessId }}</td>
              </tr>
              <tr>
                <td>Whatsapp Business Name</td>
                <td>{{ wappIntegrationDetails.config.verifiedName }}</td>
              </tr>
              <tr>
                <td>Whatsapp Business Number</td>
                <td>{{ wappIntegrationDetails.config.displayPhoneNumber }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>

    <v-layout row wrap style="padding-top: 20px;">
      <v-flex xs12 sm12 md12 style="padding: 1%;">
        <v-card>
          <v-card-title>
            <v-icon large>mdi-money</v-icon>
            <span class="title" style="padding-left: 5px">Billing</span>
          </v-card-title>
          <v-card-text>
            <div align="center">
              <h4>Current Balance</h4>
              <h1 style="padding-bottom: 1.5%;">
                {{ $store.state.companyDetails.whatsappCredits }} Credits
              </h1>
              <p>
                GoDial CRM charges 1 credit for every message sent or received
                via this platform. You will also need to recharge your Meta
                Account and pay meta charges seperately.
              </p>
            </div>

            <div v-if="$store.state.companyDetails.country == 'India'">
              <v-radio-group v-model="selectedCreditPackage">
                <v-radio label="Buy 1000 credits Rs.300 + GST" value="1000"></v-radio>
                <v-radio label="Buy 5000 credits Rs.1250 + GST" value="5000"></v-radio>
                <v-radio label="Buy 10000 credits Rs.2000 + GST" value="10000"></v-radio>
                <v-radio label="Buy 50000 credits Rs.5000 + GST" value="50000"></v-radio>
              </v-radio-group>
              <v-btn color="primary" @click="buyCredits">Buy</v-btn>
            </div>

            <div v-else>
              <v-radio-group v-model="selectedCreditPackageForeign">
                <v-radio label="Buy 1000 credits $5 + Tax" value="1"></v-radio>
                <v-radio label="Buy 5000 credits $20 + Tax" value="5"></v-radio>
                <v-radio label="Buy 10000 credits $35 + Tax" value="10"></v-radio>
                <v-radio label="Buy 50000 credits $100 + Tax" value="50"></v-radio>
              </v-radio-group>
              <v-btn color="primary" @click="buyCredits">Buy</v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import axios from "axios";
// import { mdiAlertBox } from "@mdi/js";
import { mapGetters } from "vuex";
import WhatsappChat from "@/components/whatsappChat";
export default {
  components: {
    WhatsappChat,
  },
  data() {
    return {
      enableWhatsapp: false,
      isConnected: false,
      whatsappRegistered: false,
      userDetails: {}, //To store whatsapp user details for authentication
      phoneNoId: "",
      sendMessageTo: "",
      businessId: "",
      message: "",
      selectedCreditPackage: 1000,
      selectedCreditPackageForeign: 10,
      // icons: {
      //   mdiAlertBox,
      // },
      goUser: {},
      wappIntegrationDetails: {},
    };
  },
  computed: {
    ...mapGetters(["ENDPOINT", "user", "companyDetails", "selectedTeam"]),
  },
  async mounted() {
    const getIntegration = await this.$http.get(
      `${this.ENDPOINT}/integrations?filter[where][companyId]=${this.user.companyId}&filter[where][type]=whatsapp`
    );

    if (
      getIntegration.body.length &&
      getIntegration.body[0].config.initializeDone
    ) {
      this.whatsappRegistered = true;
      this.wappIntegrationDetails = getIntegration.body[0];
    }
  },
  created() {
    this.refresh();
  },
  methods: {
    refresh() {
      var self = this;

      this.enableWhatsapp = this.$store.state.companyDetails.enableWhatsapp;

      function loadScript(url, callback) {
        var script = document.createElement("script");
        script.type = "text/javascript";
        if (script.readyState) {
          // only required for IE <9
          script.onreadystatechange = function () {
            if (
              script.readyState === "loaded" ||
              script.readyState === "complete"
            ) {
              script.onreadystatechange = null;
              callback();
            }
          };
        } else {
          //Others
          script.onload = function () {
            callback();
          };
        }

        script.src = url;
        document.getElementsByTagName("head")[0].appendChild(script);
      }

      loadScript("https://cdn.paddle.com/paddle/paddle.js", function () {
        if (self.$store.getters.isPaddleStaging == "true") {
          Paddle.Environment.set("sandbox");
        }

        //alert("Paddle key ", self.$store.getters.paddleKey);
        Paddle.Setup({ vendor: Number(self.$store.getters.paddleKey) });
      });
    },
    async handleDeletePopup() {
      const self = this;
      await self
        .$swal({
          type: "warning",
          text: "Are you sure you want to delete?",
          showCancelButton: true,
          confirmButtonColor: "#31a848",
          cancelButtonColor: "#F44336",
          confirmButtonText: "Yes",
          cancelButtonText: "No",
        })
        .then(async (yes) => {
          // user confirmation
          if (yes.value) {
            try {
              // Getting facebook unsubscribe response
              const fbResponse = await this.$http.post(`${this.ENDPOINT}/whatsapps/unsubscribe`);
              console.log("fbResponse: ", fbResponse.body);
              // Deleting integrations
              const response = await this.$http.delete(
                `${this.ENDPOINT}/integrations/${this.wappIntegrationDetails.id}`
              );

              self.$swal({
                type: "success",
                text: "Your account is successfully deleted",
              });
              window.location.reload();
            } catch (error) {
              console.log("Error: ", error);
              self.$swal({
                type: "error",
                text: "Something went wrong! Please try again.",
              });
            }
          } else {
            console.log("no fired");
          }
        });
    },

    async buyCredits() {
      const self = this;
      //Check which country the company belongs to
      if (this.$store.state.companyDetails.country == "India") {
        if (!this.$store.state.companyDetails.paymentProfile.activated) {
          return this.$swal({
            title: "Error!",
            text:
              "Please check and update your payment profile, before generating invoice",
            type: "error",
            showCancelButton: true,
            confirmButtonColor: "#1bca3f",
            cancelButtonColor: "#d33",
            confirmButtonText: "Go to Subscribe",
          })
            .then((willGo) => {
              if (willGo.value) {
                self.$router.push({ path: "/payments" });
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }

        try {
          let response = await this.$http.post(
            `${this.$store.state.ENDPOINT}/billings/create-payment-invoice-whatsapp`,
            {
              creditPackage: Number(self.selectedCreditPackage),
            }
          );

          if (
            response.data &&
            response.data.success &&
            response.data.invoiceLink
          ) {
            // Redirect to Zoho payment URL provided by your server
            window.location.href = response.data.invoiceLink;
          } else {
            return self.$swal(
              "Error",
              "Failed to initiate payment process. Please try again.",
              "error"
            );
          }
        } catch (err) {
          console.log("err", err);

          return this.$swal({
            title: "Error!",
            text: err.body.error.message,
            type: "error",
            showCancelButton: true,
            confirmButtonColor: "#1bca3f",
            cancelButtonColor: "#d33",
            confirmButtonText: "Go to Subscribe",
          })
            .then((willGo) => {
              if (willGo.value) {
                self.$router.push({ path: "/payments" });
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      } else {
        let discounts = {
          1000: "", // No discount, base price
          5000: "5USD_CREDIT_WHTSAPP", // Discount for 5000 credits
          10000: "15USD_CREDIT_WHTSAPP", // Discount for 10000 credits
          50000: "150USD_CREDIT_WHTSAPP", // Discount for 50000 credits
        };
        let couponCode = discounts[self.selectedCreditPackageForeign * 1000];

        // alert(couponCode)

        //use paddle to checkout
        var productId = self.$store.state.whatsappCreditProduct;

        Paddle.Checkout.open({
          product: productId,
          email: self.$store.getters.user.email,
          quantity: Number(self.selectedCreditPackageForeign),
          passthrough: self.$store.getters.user.companyId,
          coupon: couponCode,
          successCallback: async function (data) {
            self.$store.dispatch("getCompanyDetails", {
              companyId: self.$store.getters.user.companyId,
            });

            self.refresh();

            try {
              self.$swal({
                type: "success",
                text:
                  "Thank you for subscribing to GoDial. Your account will updated shortly, Kindly refresh after 1 minute.",
              });
            } catch (e) {
              console.log(e);
            }
          },
          closeCallback: function (data) {
            self.$store.dispatch("getCompanyDetails", {
              companyId: self.$store.state.companyDetails.id,
            });

            self.refresh();

            try {
              self.$swal({
                type: "error",
                text: "Some error occured",
              });
            } catch (e) {
              console.log(e);
            }
          },
        });
      }
    },
    updateEnableWhatsapp() {
      var self = this;

      self.$http
        .patch(
          `${this.$store.getters.ENDPOINT}/companies/${self.$store.getters.user.companyId}`,
          { enableWhatsapp: self.enableWhatsapp }
        )
        .then((response) => {
          self.$store.dispatch("getCompanyDetails", {
            companyId: self.$store.getters.user.companyId,
          });
        })
        .catch((err) => {
          self.$swal({
            type: "error",
            text: "Something went wrong. Couldn't Update",
          });
        });
    },
    checkLoginStatus() {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await new Promise(FB.getLoginStatus);

          if (response.status === "connected") {
            this.isConnected = true;
          }

          this.userDetails = response.authResponse;
          // Getting integration details to check if integration details already exists
          const getIntegration = await axios.get(
            `${this.ENDPOINT}/integrations?filter[where][companyId]=${this.user.companyId}&filter[where][type]=whatsapp&access_token=${this.user.token}`
          );

          if (getIntegration.data.length) {
            const data = {
              config: {
                ...getIntegration.data.config,
                tempAccessToken: this.userDetails,
              },
            };
            const updateIntegration = await axios.patch(
              `${this.ENDPOINT}/integrations?filter[where][companyId]=${this.user.companyId}&filter[where][type]=whatsapp&access_token=${this.user.token}`,
              data
            );
          }

          resolve(response);
        } catch (error) {
          console.error("Error in checkLoginStatus:", error);
          reject(error);
        }
      });
    },

    async launcwpSignup() {
      const response = await new Promise((resolve) => {
        FB.getLoginStatus((response) => {
          resolve(response);
        });
      });

      if (response.status != "connected") {
        // Launch Facebook login
        FB.login(
          (response) => {
            if (response.authResponse) {
              const accessToken = response.authResponse.accessToken;
              // Use this token to call the debug_token API and get the shared WABA's ID
            } else {
              console.log("User cancelled login or did not fully authorize.");
            }
            // After FB.login() resolves, check login status again
            this.checkLoginStatus().then(() => {
              console.log("isConnected", this.isConnected);
              console.log("this.whatsappRegistered", this.whatsappRegistered);
            });
          },
          {
            config_id: "1397487014215285",
            response_type: "code",
            override_default_response_type: true,
            extras: {
              setup: {
                // Prefilled data can go here
              },
            },
          }
        );
      } else {
        this.isConnected = true;
        this.userDetails = response.authResponse;
        //Check if phone and business id is available in account
        // const getUser = await axios.get(`${this.ENDPOINT}/accounts/${this.user.id}?access_token=${this.user.token}`);
        // if (getUser.data.whatsappBusinessId || getUser.data.whatsappNoId) {
        //     this.whatsappRegistered = true;
        // }
        const getIntegration = await axios.get(
          `${this.ENDPOINT}/integrations?filter[where][companyId]=${this.user.companyId}&filter[where][type]=whatsapp&access_token=${this.user.token}`
        );

        if (
          getIntegration.data.length &&
          (getIntegration.data[0].config.whatsappBusinessId ||
            getIntegration.data[0].config.whatsappNoId)
        ) {
          this.whatsappRegistered = true;
        }
      }
    },
    // To send template messages to whatsapp
    async sendMessage(accessToken, contactNumber) {
      try {
        const getUser = await await axios.get(
          `${this.ENDPOINT}/integrations?filter[where][companyId]=${this.user.companyId}&filter[where][type]=whatsapp&access_token=${this.user.token}`
        );

        const data = {
          messaging_product: "whatsapp",
          to: contactNumber,
          type: "template",
          template: {
            name: "hello_world",
            language: {
              code: "en_US",
            },
          },
        };
        const response = await axios.post(
          `https://graph.facebook.com/v18.0/${getUser.data[0].config.whatsappNoId}/messages`,
          data,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.data.messages.length) {
          this.$swal({
            type: "success",
            text: "Message sent successfully",
          });
        }
      } catch (e) {
        console.log("send messge error: ", e);
        this.$swal({
          type: "error",
          text: e.response.data.error.message,
        });
      }
    },

    // Send text msg on whatsapp
    async sendTextMessage(accessToken) {
      // console.log("Send message fired");
      // console.log("access Token: ", accessToken);
      const contactNumber = this.sendMessageTo;
      const getUser = await axios.get(
        `${this.ENDPOINT}/integrations?filter[where][companyId]=${this.user.companyId}&filter[where][type]=whatsapp&access_token=${this.user.token}`
      );
      const data = {
        recipient_type: "individual",
        to: contactNumber,
        type: "text",
        text: {
          body: this.message,
        },
      };

      // const headers = [`Authorization: Bearer ${accessToken}`, 'Content-Type: application/json'];
      const response = await axios.post(
        `https://graph.facebook.com/v18.0/${getUser.data[0].config.whatsappNoId}/v1/messages`,
        data,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.messages.length) {
        this.$swal({
          type: "success",
          text: "Message sent successfully",
        });
      } else {
        this.$swal({
          type: "error",
          text: "An error occurred while sending message. Please try again!",
        });
      }
    },
    // async saveWhatsappDetails() {
    //     console.log("server endpoint: ", this.ENDPOINT);
    //     console.log("user: ", this.user);
    //     const data = {
    //         whatsappNoId: this.phoneNoId,
    //         whatsappBusinessId: this.businessId
    //     }
    //     try {
    //         const resp = await axios.patch(`${this.ENDPOINT}/accounts/${this.user.id}?access_token=${this.user.token}`, data);
    //         console.log("Response from account: ", resp.data);
    //         if (resp.data) this.whatsappRegistered = true;
    //     } catch (error) {
    //         console.log("Error: ", error);
    //         this.$swal({
    //             type: "error",
    //             text: "An error occurred. Please try again!",
    //         })
    //     }

    // },
    async saveWhatsappDetails() {
      // console.log("accessToken: ", this.userDetails);

      const data = {
        type: "whatsapp",
        enabled: true,
        config: {
          whatsappNoId: this.phoneNoId,
          whatsappBusinessId: this.businessId,
          whatsappMessageTemplates: ["hello_world"],
          tempAccessToken: this.userDetails.authResponse,
        },
        companyId: this.user.companyId,
      };
      try {
        const resp = await axios.post(
          `${this.ENDPOINT}/integrations?access_token=${this.user.token}`,
          data
        );

        if (resp.data) this.whatsappRegistered = true;
      } catch (error) {
        console.log("Error: ", error);
        this.$swal({
          type: "error",
          text: "An error occurred. Please try again!",
        });
      }
    },
  },
};
</script>
<style scoped>
.fb-button {
  color: white;
}

.intframe {
  height: 100vh;
  width: 100%;
}

@media (min-width: 1200px) {
  .enableFacebookPadding {
    padding-left: 30%;
    padding-right: 30%;
  }
}

/* Landscape tablets and medium desktops */
@media (min-width: 992px) and (max-width: 1199px) {
  .enableFacebookPadding {
    padding-left: 30%;
    padding-right: 30%;
  }
}

/* Portrait tablets and small desktops */
@media (min-width: 768px) and (max-width: 991px) {
  .enableFacebookPadding {
    padding-left: 20%;
    padding-right: 20%;
  }
}

/* Landscape phones and portrait tablets */
@media (max-width: 767px) {
  .enableFacebookPadding {
    padding-left: 1%;
    padding-right: 1%;
  }
}

/* Portrait phones and smaller */
@media (max-width: 480px) {
  .enableFacebookPadding {
    padding-left: 1%;
    padding-right: 1%;
  }
}
</style>
